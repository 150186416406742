import ampHandler from "@/utils/apm";

interface rejectionEvent {
  promise: Promise<any>;
  reason: {
    message: string;
    stack: string;
  };
}

const rejectionHandler = (e:rejectionEvent) => {
  const { reason } = e || {};
    console.log("errorCatch", reason.message, reason.stack);
    if (reason?.message || reason?.stack) {
      ampHandler.uploadEventInfo("H5JSError", Date.now(), "=>" + JSON.stringify({
        message: reason.message || '',
        stack: reason.stack || '',
      }));
    }
}
const errorCatch = () => {
  if (!window) return
  window.onerror = (message, source, line, column, error) => {
    // 全局捕获js错误
    console.log("errorCatch", message, source, line, column, error);
    ampHandler.uploadEventInfo("H5JSError", Date.now(), "=>" + JSON.stringify({
      message,
      source,
      line,
      column,
      error
    }));
    return false
  };
  // 全局捕获未被catch的promise错误
  window.addEventListener('unhandledrejection', rejectionHandler, true);
    
}

const removeCatch = () => {
  window.onerror = null
  window.removeEventListener('unhandledrejection', rejectionHandler, true);
}
export {
  errorCatch,
  removeCatch
};
