import dayjs from 'dayjs';
import ampHandler from "@/utils/apm";

export function calculateCountdownTime(time: string) {
  const diff = dayjs(dayjs()).diff(time, 's');
  const totalSeconds = Math.abs(diff);
  var ONE_DAY = 86400;
  var ONE_HOUR = 3600;
  var ONE_MINUTE = 60;

  var days = Math.floor(totalSeconds / ONE_DAY);
  var hours = Math.floor(totalSeconds % ONE_DAY / ONE_HOUR);
  var minutes = Math.floor(totalSeconds % ONE_DAY % ONE_HOUR / ONE_MINUTE);
  var seconds = Math.floor(totalSeconds % ONE_DAY % ONE_HOUR % ONE_MINUTE);
  return {
    days,
    hours,
    minutes,
    seconds,
    diff
  };
}

export function getDurationLabel(thisDay: string) {
  if (!thisDay) return '';
  thisDay = thisDay.slice(0, 10);
  let result: any;
  if (thisDay >= dayjs().format('YYYY-MM-DD')) {
    result = '今天';
  } else if (thisDay === dayjs().subtract(1, 'day').format('YYYY-MM-DD')) {
    result = '昨天';
  } else if (thisDay === dayjs().subtract(2, 'day').format('YYYY-MM-DD')) {
    result = '前天';
  } else {
    const day: any = Math.abs(dayjs(thisDay).diff(dayjs(), 'd'));
    if (day >= 3 && day <= 6) {
      result = day + '天前';
    } else if (day >= 7 && day <= 28) {
      result = Math.floor(day / 7) + '天前';
    } else {
      let months = Math.abs(dayjs(thisDay).diff(dayjs(), 'M'));
      months = months || 1;
      result = months + '月前';
    }
  }
  return result;
}

export function joinQueryArgs(args: any) {
  const items: any = [];
  Object.entries(args).map((entry) => {
    items.push(`${entry[0]}=${entry[1]}`);
  });

  return items.join('&');
}

export function padding0(num: number) {
  let numLabel = '000' + num;
  return numLabel.slice(-2);
}

export const CONSTANTS = {
  WEAPP_MINI_QUJIA_ID: 'gh_1175f03120c9',
}

export const client = {
  isInApp: () => {
    const userAgent: any = navigator.userAgent.toLowerCase();
    const isInApp: any = userAgent.includes('mcd-ios') || userAgent.includes('mcd-android') || userAgent.includes('mcd-harmony');
    return isInApp ? true : false;
  },
  weappMiniContext: () => {
    const wxObj: any = (window as any).wx;
    if (window.navigator.userAgent.match(/MicroMessenger/)) {
      if (wxObj?.miniProgram) return wxObj;
    }
    return false;
  },
  isWx: () => {
    const s = window.navigator.userAgent.toLowerCase().match(/MicroMessenger/i)+''
    return s === "micromessenger"
  },
  connectWebViewJavascriptBridge: (callback: any) => {
    if (window.WebViewJavascriptBridge) {
      callback(window.WebViewJavascriptBridge);
    } else {
      document.addEventListener(
        'WebViewJavascriptBridgeReady'
        , function () {
          if (window.WebViewJavascriptBridge) {
            callback(window.WebViewJavascriptBridge)
          } else {
            console.log('WebViewJavascriptBridgeReady but no WebViewJavascriptBridge');
            ampHandler.uploadEventInfo(
              "WebViewJsBridgeError",
              Date.now(),
              "WebViewJavascriptBridgeReady but no WebViewJavascriptBridge",
            );
          }
        },
        false
      );
    }
  }
}

export const APPID = 'wx4ed9e1f4e0f3eeb0';

const baseUrl = 'https://cdn.mcd.cn/';
export const CDN_URLS = {
  downloadTop: 'https://img.mcd.cn/gallery/6cf94bd1b9f6a9ad.png',
  downloadBg: 'https://img.mcd.cn/gallery/115cf520859b12da.png',
  downloadForCDNBg: baseUrl + 'cma/download/cny-promote.jpg',
  loadingGif: 'https://img.mcd.cn/mini/main/images/loading.gif',
}