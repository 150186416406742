/**
 * desc: 个性化落地页
 * UI：https://www.figma.com/design/HUx4lRorD2mKLrRg4SQZiQ/CNN-%E6%8E%A2%E7%B4%A2?node-id=1845-1029&node-type=frame&t=av02pawxP8ryc6ni-0
 */
import React, { useState, useEffect, useRef } from "react";
import { Modal } from "antd";
import "antd/dist/antd.less";
import Toast from 'light-toast';
import Cookies from 'js-cookie';
import api from "@/common/net/apis";
import apis from "@/apps/wifiVerify/apis";
import "@/common/sensor";
import { client } from "@/common/common";
import '@/assets/styles/individualization.less';

const wx = (window as any).wx;
enum CardType {
  SINGLE = 'SINGLE',
  MULTIPLE = 'MULTIPLE'
}

enum ReceiveStatus {
  NOT_RECEIVED = 0, // 未领取
  RECEIVED_SUCCESS = 1, // 领取成功
  RECEIVED_FAILED = 2 // 领取失败
}

const IMAGES = {
  actionButton: 'https://i.cdncf.xyz/00e050528e644d06a0bab84e21bf9f4e.png',
  edges: {
    bottomJagged: "https://img.mcd.cn/mini/main/images/personalized-bottom-jagged-edge.png",
    topJagged: "https://img.mcd.cn/mini/main/images/personalized-top-jagged-edge.png",
    topShadow: 'https://img.mcd.cn/mini/main/images/personalized-top-shadow.png',
    bottomShadow: 'https://img.mcd.cn/mini/main/images/personalized-bottom-shadow.png'
  }
}
// 添加预加载图片工具函数
const preloadImages = (images: string[]) => {
  images.forEach(src => {
    const img = new Image();
    img.src = src;
  });
};
const closeIcon = 'https://img.mcd.cn/gallery/dfe39c7beb80221b.png'
const BASE_WIDTH = 375;
const screenWidth = window.screen.width;
// 计算比例的工具函数
const calcRatio = (size: number) => (size * screenWidth) / BASE_WIDTH;
// 组织相关常量到对象中
const RATIOS = {
  empty: {
    pageTop: calcRatio(120),
  },

  slogan: {
    height: calcRatio(145),
  },

  machine: {
    height: calcRatio(345),
    cardMarginTop: calcRatio(97),
    cardMarginLeft: calcRatio(36),
    gifMarginLeft: calcRatio(28),
  },

  couponGif: {
    height: calcRatio(220),
    width: calcRatio(279),
  },

  card: {
    single: {
      height: calcRatio(202),
      width: calcRatio(263),
      couponHeight: calcRatio(121),
    },
    listItem: {
      height: calcRatio(90),
    },
    lowerEdge: calcRatio(10),
  },

  button: {
    height: calcRatio(56),
  },

  shadow: {
    top: calcRatio(15),
    bottom: calcRatio(20),
  },
}

// 点击
const ANIMATION = {
  CARD_SHOW_DELAY: 400,
  BOTTOM_SHADOW_DELAY: 1000,
}

// 小程序schema url参数类型
const SCHEMA_TYPE = {
  // 首页
  INDEX: 3,
  // 卡券列表
  COUPON: 4,
};

const Individualization = () => {
  const [stopGifAnimation, setStopGifAnimation] = useState(false);
  // 点击领取展示卡片
  const [showCardView, setShowCardView] = useState(false);
  const [reachedBottom, setReachedBottom] = useState('');
  const [titleFontSizes, setTitleFontSizes] = useState<{ [key: string]: boolean }>({});
  const [pageConfig, setPageConfig] = useState<any>(null);
  const [showEmpty, setShowEmpty] = useState(false);
  const [errorModalVisible, setErrorModalVisible] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [singleCardTitleOverflow, setSingleCardTitleOverflow] = useState(false);
  const elementRef = useRef(null);
  const indexSchemaRef = useRef('');
  const couponSchemaRef = useRef('');
  // 底部阴影是否显示
  const isShowBottomShadow = useRef(true);

  useEffect(() => {
    const scrollView = document.querySelector('.cardListWrapper');
    const handleScroll = (e: Event) => {
      const target = e.target as HTMLElement;
      const scrollTop = target.scrollTop;
      const scrollHeight = target.scrollHeight;
      const clientHeight = target.clientHeight;

      // 检查是否滚动到底部（允许1px的误差）
      const isAtBottom = Math.abs(scrollHeight - scrollTop - clientHeight) <= 1;
      if (isAtBottom && isShowBottomShadow.current) {
        // 滑动到底部 并且 底部阴影是显示的 隐藏底部阴影
        isShowBottomShadow.current = false;
        setReachedBottom('bottomShadowFadeOut');
      } else if (!isAtBottom && !isShowBottomShadow.current) {
        // 没有滑动到底部 并且 底部阴影是隐藏的 显示底部阴影
        isShowBottomShadow.current = true;
        setReachedBottom('bottomShadowFadeIn');
      }
    };
    if (scrollView) {
      scrollView.addEventListener('scroll', handleScroll);
      return () => {
        scrollView.removeEventListener('scroll', handleScroll);
      };
    }
    if (showCardView && pageConfig?.cardType === CardType.MULTIPLE) {
      // 等待下一帧渲染完成后再检查
      requestAnimationFrame(() => {
        pageConfig?.multipleCards?.forEach((item: any, index: number) => {
          const element = document.getElementById(`title${index}`);
          if (element) {
            // 获取实际内容宽度和容器宽度
            const actualWidth = element.scrollWidth;
            const containerWidth = element.offsetWidth;
            // 更精确的溢出检测
            const isOverflowing = actualWidth > containerWidth;
            setTitleFontSizes(prev => ({
              ...prev,
              [`title${index}`]: isOverflowing
            }));
          }
        });
      });
    }
    if (showCardView && pageConfig?.cardType === CardType.SINGLE) {
      requestAnimationFrame(() => {
        const titleElement: any = document.querySelector('.singleItem .title');
        if (titleElement) {
          const actualWidth = titleElement.scrollWidth;
          const containerWidth = titleElement.offsetWidth;
          setSingleCardTitleOverflow(actualWidth > containerWidth);
        }
      });
    }
  }, [showCardView, pageConfig?.multipleCards, pageConfig?.singleCard]);

  useEffect(() => {
    const imagesToPreload = [
      IMAGES.actionButton,
      ...Object.values(IMAGES.edges)
    ];
    preloadImages(imagesToPreload);
    getPageConfig();
    document.documentElement.style.setProperty('--listItemCardHeightRatio', `${RATIOS.card.single.height}Px`);
  }, []);

  useEffect(() => {
    window.document.title = pageConfig?.uiConfig?.pageTitle || '';
    if (pageConfig?.uiConfig) {
      initShareOption(pageConfig.uiConfig);
    }
  }, [pageConfig?.uiConfig]);

  useEffect(() => {
     fetchSchemaUrl(SCHEMA_TYPE.INDEX, indexSchemaRef);
     fetchSchemaUrl(SCHEMA_TYPE.COUPON, couponSchemaRef);
  }, []);

  /**
   * 查询小程序跳转url
   * @param type
   * @param urlRef
   */
  const fetchSchemaUrl = (type: number, urlRef: any) => {
    apis.getUrlSchema({type}).then((res: any) => {
      if (res?.success && res.data) {
        urlRef.current = res.data;
      }
    }).catch((err: any) => {
      console.log(err);
    });
  };

  const getParamsFromRouter = () => {
    const searchParams = new URLSearchParams(window.location.search);
    const taskId = searchParams.get('taskId');
    const componentId = searchParams.get('componentId');
    const pageSource = searchParams.get('page_source');
    const mapId = searchParams.get('mapId');
    return { taskId, componentId, pageSource, mapId };
  }

  const getPageConfig = async () => {
    try {
      const { taskId, componentId, mapId } = getParamsFromRouter();
      const response = await api.getIndividualizationpage({
        "taskId": taskId,
        "componentId": componentId,
        "mapId": mapId,
      });
      if (!response || !response.data) {
        throw new Error('Invalid response data');
      }

      const coupons = response.data?.coupons || [];
      const couponLength = coupons.length;
      pageViewTrack(response.data?.received);
      if(response.data?.received === ReceiveStatus.RECEIVED_SUCCESS){
        setTimeout(() => {
          setShowCardView(true)
        }, ANIMATION.CARD_SHOW_DELAY);
        setTimeout(() => {
          setReachedBottom('bottomShadowFadeIn');
        }, ANIMATION.BOTTOM_SHADOW_DELAY);
      }
      setPageConfig({
        status: response.data?.received,
        multipleCards: couponLength > 1 ? coupons : null,
        singleCard: couponLength === 1 ? coupons[0] : null,
        cardType: couponLength > 1 ? CardType.MULTIPLE : CardType.SINGLE,
        uiConfig: (() => {
          try {
            if (!response.data?.componentData) return null;
            const parsed = JSON.parse(response.data.componentData);
            return parsed.reduce((acc: any, item: { key: string; value: any }) => {
              acc[item.key] = item.value;
              return acc;
            }, {});
          } catch (error) {
            return null;
          }
        })(),
      });
    } catch (err) {
      setPageConfig(null);
      setShowEmpty(true)
    }
  }

  const receiveCoupon = async () => {
    btnClickTrack();
    if (pageConfig?.status === ReceiveStatus.RECEIVED_SUCCESS) {
      // 去使用 跳转到小程序卡券列表
      if (indexSchemaRef.current) {
        window.location.href = couponSchemaRef.current;
      }
    } else {
      // 领取优惠券
      try {
        const { taskId, componentId, mapId } = getParamsFromRouter();
        const response = await api.bindIndividualizationCoupon({
          "taskId": taskId,
          "componentId": componentId,
          "mapId": mapId,
        });
        if (response?.code === 401) {
          // 未登录 跳转登录页拉起登录
          Cookies.remove('sid');
          window.location.href = './login';
          return;
        }
        // 检查 response 的 success 状态
        if (!response.success) {
          Toast.info('活动太火爆，请稍后再试', 2000);
          return;
        }
        if(response.data?.received === ReceiveStatus.RECEIVED_SUCCESS) {
          // 成功后关闭gif动画
          setStopGifAnimation(true)
          const coupons = response.data?.coupons || [];
          const couponLength = coupons.length;
          setTimeout(() => {
            setShowCardView(true);
          }, ANIMATION.CARD_SHOW_DELAY);
          setTimeout(() => {
            setReachedBottom('bottomShadowFadeIn');
          }, ANIMATION.BOTTOM_SHADOW_DELAY);
          setPageConfig({
            uiConfig: pageConfig?.uiConfig,
            status: response.data?.received,
            cardType: couponLength > 1 ? CardType.MULTIPLE : CardType.SINGLE,
            multipleCards: couponLength > 1 ? coupons : null,
            singleCard: couponLength === 1 ? coupons[0] : null,
          })
        }
        if (response?.data?.received === ReceiveStatus.RECEIVED_FAILED) {
          const message = response.data?.errMsg || '领取失败，请稍后重试';
          setPageConfig({
            uiConfig: pageConfig?.uiConfig,
            status: response.data?.received,
            cardType: CardType.SINGLE,
            multipleCards: [],
            singleCard: [],
          })
          setErrorMessage(message);
          setErrorModalVisible(true);
          modalViewTrack();
        }
      } catch (error: any) {
        // 获取错误信息
        Toast.info('活动太火爆，请稍后再试', 2000)
      }
    }
  }

  /**
   * 微信浏览器分享
   * @param shareData
   */
  const initShareOption = (shareData: any = {}) => {
    if (client.weappMiniContext()) {
      wx.miniProgram.getEnv((res: any) => {
        if (!res.miniprogram) {
          // 微信网页
          initShareInWechat(shareData);
        }
      });
    }
  };

  const initShareInWechat = async (shareData: any) => {
    const res = await api.getWechatAuth() || {};
    const data = res?.data || {};
    if (res.success && JSON.stringify(data) !== "{}") {
      wx.config({
        debug: false,
        appId: data.appId,
        timestamp: data.timestamp, // 必填，生成签名的时间戳
        nonceStr: data.nonce, // 必填，生成签名的随机串
        signature: data.sign, // 必填，签名
        jsApiList: [
          "checkJsApi",
          "updateTimelineShareData",
          "updateAppMessageShareData",
          "onMenuShareAppMessage",
        ],
      });
      wx.ready(function () {
        const { H5ShareDesc, H5Share } = shareData;
        wx.updateTimelineShareData({
          // 自定义“分享到朋友圈”及“分享到QQ空间”按钮的分享内容
          title: H5ShareDesc, // 分享标题
          link: window.location.href, // 分享链接
          imgUrl: H5Share, // 分享图标
          success: function () {},
          cancel: function () {},
        });
        wx.onMenuShareAppMessage({
          // 获取“分享给朋友”按钮点击状态及自定义分享内容接口（即将废弃）
          title: H5ShareDesc, // 分享标题
          desc: H5ShareDesc, // 分享描述
          link: window.location.href, // 分享链接
          imgUrl: H5Share, // 分享图标
          type: "link", // 分享类型,music、video或link，不填默认为link
          dataUrl: "", // 如果type是music或video，则要提供数据链接，默认为空
          success: function () {},
        });
        wx.updateAppMessageShareData({
          // 自定义“分享给朋友”及“分享到QQ”按钮的分享内容
          title: H5ShareDesc, // 分享标题
          desc: H5ShareDesc, // 分享描述
          link: window.location.href, // 分享链接
          imgUrl: H5Share, // 分享图标
          success: function () {},
          cancel: function () {},
        });
      });
    }
  };

  const handleGotoHome = () => {
    modalBtnClickTrack();
    setErrorModalVisible(false);
    if (indexSchemaRef.current) {
      window.location.href = indexSchemaRef.current;
    }
  }

  const closeInfoModal = () => {
    setErrorModalVisible(false)
  }

  const pageViewTrack = (flag: any = 1) => {
    const { pageSource } = getParamsFromRouter();
    (window as any).sensors?.track("customizePageView", {
      page_source: pageSource,
      belong_page: '惊喜优惠券',
      page_status: flag,
      promotion_name: '个性化落地页'
    });
  };

  const modalViewTrack = () => {
    (window as any).sensors?.track("customizePageView", {
      belong_page: '惊喜优惠券',
      popup_name: '领取失败',
      popup_type: '提示弹窗',
      promotion_name: '个性化落地页'
    });
  };

  const btnClickTrack = () => {
    const { pageSource } = getParamsFromRouter();
    (window as any).sensors?.track("buttonClick", {
      page_source: pageSource,
      belong_page: "惊喜优惠券",
      button_name: pageConfig?.status === ReceiveStatus.RECEIVED_SUCCESS ? '去使用' : '点击领取',
      promotion_name: '个性化落地页'
    });
  };

  const modalBtnClickTrack = () => {
    (window as any).sensors?.track("buttonClick", {
      belong_page: "惊喜优惠券",
      popup_name: '领取失败',
      popup_type: '提示弹窗',
      button_name: '去看看',
      promotion_name: '个性化落地页',
      url: '/pages/coupon/index'
    });
  };

  return (
    <>
      {
        !showEmpty ? (
          <div className="couponWrapper" style={{ 
            backgroundColor: `#${pageConfig?.uiConfig?.pageBackgroundDefault}`,
            backgroundImage: `url(${pageConfig?.uiConfig?.pageBackground})`,
          }}>
            <div className="contentWrapper">
              <img 
                src={pageConfig?.uiConfig?.pageTitleImage} 
                alt="" 
                className="slogan" 
                style={{
                  height: `${RATIOS.slogan.height}Px`,
                }} 
              />
              <div className="machineWrapper">
                <img src={pageConfig?.uiConfig?.pageMainImage} alt="" className="machine" style={{ height: `${RATIOS.machine.height}Px` }} />
                {
                 pageConfig?.status !== ReceiveStatus.RECEIVED_SUCCESS ? (
                    <img src={pageConfig?.uiConfig?.pageGetCoupon} alt="" className={stopGifAnimation ? 'gifFadeOut couponGif' : 'couponGif'} style={{ width: `${RATIOS.couponGif.width}Px`, height: `${RATIOS.couponGif.height}Px`, top: `${RATIOS.machine.cardMarginTop}Px`, left: `${RATIOS.machine.gifMarginLeft}Px` }} />
                  ) : null
                }

                {
                  showCardView && pageConfig?.cardType === CardType.SINGLE ? (
                    <div className={`singleCardWrapper cardSingleWrapperFadeIn`} style={{ width: `${RATIOS.card.single.width}Px`, height: `${RATIOS.card.single.height}Px`, top: `${RATIOS.machine.cardMarginTop}Px`, left: `${RATIOS.machine.cardMarginLeft}Px` }} >
                      <img src={IMAGES.edges.topShadow} className={`topShadow cardSingleWrapperFadeIn`} alt="" style={{ height: `${RATIOS.shadow.top}Px` }} />
                      <div className="cardScrollView singleCardWrapperTransfromDown" style={{ width: `${RATIOS.card.single.width}Px`, height: `${RATIOS.card.single.height}Px` }} >
                        <div className="singleItem">
                          <view className="cardImg"
                            style={{ height: `${RATIOS.card.single.couponHeight}Px` }}
                          >
                          <img
                            src={pageConfig?.singleCard?.couponPic}
                            className="couponPic"
                            alt=""
                          />
                          </view>
                          <div className="couponInfo">
                            <div 
                              className={`title ${singleCardTitleOverflow ? 'smallFont' : ''}`} 
                            >
                              {pageConfig?.singleCard?.couponName}
                            </div>
                            <div className="priceWrapper">
                              <div className="priceInfo">
                              <div className="unit">¥</div>
                                <div className="price beforePrice">
                                  {String(pageConfig?.singleCard?.price)?.split('.')?.[0]}
                                </div>
                                {
                                  String(pageConfig?.singleCard?.price)?.split('.')?.[1] ? (
                                    <div>  
                                      <div className="price beforePrice">.</div>
                                      <div className="price afterPrice">{String(pageConfig?.singleCard?.price)?.split('.')?.[1]?.substring(0, 1)}</div>
                                    </div>
                                  ) : null
                                }
                              </div>
                              <div className="txt">用券价格</div>
                            </div>
                          </div>
                          <img src={IMAGES.edges.bottomJagged} alt="" className="lowerEdge" style={{ width: `${RATIOS.card.single.width}Px`, height: `${RATIOS.card.lowerEdge}Px` }} />
                        </div>
                      </div>
                    </div>
                  ) : null
                }
                {
                  showCardView && pageConfig?.cardType === CardType.MULTIPLE ? (
                    <div className="cardListContentWrapper" style={{ width: `${RATIOS.card.single.width}Px`, height: `${RATIOS.card.single.height}Px`, top: `${RATIOS.machine.cardMarginTop}Px`, left: `${RATIOS.machine.cardMarginLeft}Px` }}>
                      <img src={IMAGES.edges.topShadow} className={`topShadow cardListWrapperFadeIn`} alt="" style={{ height: `${RATIOS.shadow.top}Px` }} />
                      <div className={`cardListWrapper cardListWrapperFadeIn`}  >
                        <div className="cardScrollView " style={{ width: `${RATIOS.card.single.width}Px`, height: `${RATIOS.card.single.height}Px` }} >
                          <div className="innerWrapper cardListWrapperTransfromDown">
                            {
                              pageConfig?.multipleCards.map((item: any, index: number) => {
                                return (
                                  <div className="listItem " key={index} >
                                    {index !== 0 ? (<img src={IMAGES.edges.topJagged} alt="" className="upperEdge" style={{ width: `${RATIOS.card.single.width}Px`, height: `${RATIOS.card.lowerEdge}Px` }} />) : null}
                                    <div className="content" style={{ width: `${RATIOS.card.single.width}Px`, height: `${RATIOS.card.listItem.height}Px` }} >
                                      <div className="couponImg">
                                        <img src={item.couponLogo} alt="" className="couponImg" />
                                      </div>
                                      <div className="line"></div>
                                      <div className="infoWrapper">
                                        <div className={`title ${titleFontSizes[`title${index}`] ? 'smallFont' : ''}`} ref={elementRef} id={`title${index}`}>{item.couponName}</div>
                                        <div className="date">
                                          {item.tradeStartTime?.split(' ')[0]?.replace(/-/g, '.')}-{item.tradeEndTime?.split(' ')[0]?.replace(/-/g, '.')}
                                        </div>
                            
                                        <div className="priceWrapper">
                                          <div className="unit">¥</div>
                                          <div className="price beforePrice">
                                            {item.price?.split('.')?.[0]}
                                            {
                                              item.price?.split('.')?.[1] ? <span>.</span> : null
                                            }
                                          </div>
                                          <div className="price afterPrice">{item.price?.split('.')?.[1]?.substring(0, 1)}</div>
                                        </div>
                                      </div>
                                    </div>
                                    <img src={IMAGES.edges.bottomJagged} alt="" className="lowerEdge" style={{ width: `${RATIOS.card.single.width}Px`, height: `${RATIOS.card.lowerEdge}Px` }} />
                                  </div>
                                )
                              })
                            }
                          </div>
                        </div>
                      </div>
                      {
                        showCardView ? (
                          <div className={`bottomShadow ${reachedBottom}`}>
                            <img src={IMAGES.edges.bottomShadow} alt="" style={{ height: `${RATIOS.shadow.bottom}Px` }} />
                          </div>
                        ) : null
                      }

                    </div>
                  ) : null
                }
              </div>
              <img
                src={
                  pageConfig?.status === ReceiveStatus.RECEIVED_SUCCESS ?
                    pageConfig?.uiConfig?.pageSuccessButton :
                    pageConfig?.uiConfig?.pageButton
                }
                alt=""
                onClick={receiveCoupon}
                className="actionButton"
                style={{
                 height: `${RATIOS.button.height}Px`
                }}
              />
              {
                pageConfig?.uiConfig?.ruleDesc ? (
                  <div className='ruleContent'>
                    <div className='title'>领取说明</div>
                    <div className='content' dangerouslySetInnerHTML={{
                      __html: `${pageConfig?.uiConfig?.ruleDesc}` }}></div>
                  </div>
                ) : null
              }
            </div>
          </div>
        ) : (
          <div className="emptyWrapper">
            <img src={'https://img.mcd.cn/mini/main/images/invoice_icon_list_empty.png'} alt="" style={{ marginTop: `${RATIOS.empty.pageTop}px` }} />
            <div className="emptyText">抱歉,页面加载失败了...</div>
            <div className="emptySubText">请稍后再尝试吧</div>
          </div>
        )
      }

      <Modal
        open={errorModalVisible}
        closable={false}
        footer={null}
        className='infoModalWrapper'
        maskStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.7)' }}
        getContainer={false}
      >
        <div className="infoModal">
          <p className="title">{errorMessage}</p>
          <div className="actionBtn" onClick={handleGotoHome}>去看看</div>
          <img src={closeIcon} onClick={closeInfoModal} alt="" className="closeIcon" />
        </div>
      </Modal>
    </>
  )
};

export default Individualization;
