import React, { useState, useEffect, useRef } from "react";
import lodash from "lodash";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";
import { Modal } from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import browserizr, { isAndroid, isIOS } from "@wezom/browserizr";
/** @ts-ignore */
import { getWechat } from "@omc/browserizr";
import * as common from "@/common/common";
import { JudgeLimitError } from "@/utils/download";
import "@/assets/styles/download.less";
import apis from "@/common/net/apis";
import barImg from "@/assets/images/bar.png";

const AutoPlaySwipeableViews: any = autoPlay(SwipeableViews);
const queryString = require("query-string");
let userAgent = navigator.userAgent.toLowerCase();
const sensors: any = (window as any).sensors;

const DOWNLOAD_PAGES: any = {
  MCD_THROUGH_APP_QQ:
    "https://a.app.qq.com/o/simple.jsp?pkgname=com.mcdonalds.gma.cn",
  MCD_ANDROID: "https://cdn.mcd.cn/cma/download/app-release.apk",
  MCD_IOS:
    "https://apps.apple.com/cn/app/%E9%BA%A6%E5%BD%93%E5%8A%B3mcdonalds-%E5%88%B0%E5%BA%97%E5%8F%96%E9%A4%90-%E9%BA%A6%E5%92%96%E5%95%A1-%E9%BA%A6%E4%B9%90%E9%80%81/id1054598922",
};

const Download = ({ banners = [], source = "app", deeplink = "" }) => {
  const [popupOpen, setPopupOpen] = useState(false);
  const [tipOpen, setTipOpen] = useState(false);
  const [descs, setDescs] = useState([
    "打开麦当劳APP失败",
    "如您未安装，请点击下载",
  ]);
  const [btnDesc, setBtnDesc] = useState("");
  const [imageConfig, setImages] = useState({
    topImage: "",
    banners: [] as string[],
  });
  const [iosJumpToAppStore, setIosJumpToAppStore] = useState(false); // 是否跳转appStore
  const currentLimit = useRef(false);

  useEffect(() => {
    document.body.style.fontSize = "14px";
    const setRem = () => {
      let vw = document.documentElement.clientWidth;
      document.documentElement.style.fontSize = vw / 7.5 + "px";
    };
    setRem();
    lodash.throttle(setRem, 100, { leading: true, trailing: false });
    window.onresize = setRem;
  });

  const downloadAtOnce = (source: any, autoOpen = false) => {
    const document: any = window.document;
    let hidden =
      document.hidden ||
      document.mozHidden ||
      document.msHidden ||
      document.webkitHidden;
    if (!hidden) {
      let href;
      if (source === "uc") {
        href = DOWNLOAD_PAGES.MCD_ANDROID;
        if (browserizr.detect(isAndroid)) {
          // href = DOWNLOAD_PAGES.MCD_ANDROID
          window.location.href = createLink(deeplink);
        } else if (browserizr.detect(isIOS)) {
          // href = DOWNLOAD_PAGES.MCD_IOS;
          window.location.href = createLink(deeplink);
        }
      } else {
        href = genDownloadLink(autoOpen);
      }
      if (href) openDownloadPage(href);
    }
  };

  const tryOpenOrDownload = (
    deeplink: string,
    source: any,
    isClickAction = false
  ) => {
    if (isClickAction && getWechat()) {
      // ags-4588 微信环境ios跳转appStore
      if (
        (userAgent.includes("iphone") || userAgent.includes("ios")) &&
        iosJumpToAppStore
      ) {
        openDownloadPage(DOWNLOAD_PAGES.MCD_IOS);
        return;
      } else {
        setPopupOpen(true);
        return;
      }
    }

    const path = window.location.pathname;
    // ou麦卡和早餐卡不主动跳转
    if (path.endsWith("/app-bfcard.html") || path.endsWith("app-ohcard.html")) {
      setTipOpen(true);
      return;
    }
    downloadAtOnce(source, !isClickAction);
  };

  const setFormerKey = (key: string) => {
    const formerImg = {
      topImage: common.CDN_URLS.downloadTop,
      banners: banners as string[],
    };

    switch (key) {
      case "downloadBreakfast":
        formerImg.topImage = "https://img.mcd.cn/gallery/6cf94bd1b9f6a9ad.png";
        formerImg.banners = ["https://cdn.mcd.cn/cma/download/app-bfcard.jpg"];
        break;
      case "downloadOhmycard":
        formerImg.topImage = "https://img.mcd.cn/gallery/6cf94bd1b9f6a9ad.png";
        formerImg.banners = ["https://cdn.mcd.cn/cma/download/app-ohcard.jpg"];
        break;
      default:
        break;
    }
    setImages(formerImg);
  };

  const querySettingImages = async () => {
    const moduleKey = getModuleKey();
    if (!moduleKey) return;

    try {
      const res = await apis.queryDownloadImageSetting(
        { moduleKey },
        {
          "Content-Type": "application/json	",
        },
        {
          timeout: 1000,
        }
      );
      if (res.success && res.code === 200) {
        const { positionList = [] } = res.data || {};
        if (positionList && positionList.length) {
          const { imageUrl = banners[0], extraInfo } = positionList[0];
          if (!extraInfo) {
            setFormerKey(moduleKey);
            return;
          }
          const {
            topPic = imageConfig.topImage,
            prompt = "",
            btnContent = "",
            jumpToAppStore = false,
          } = JSON.parse(extraInfo);
          setIosJumpToAppStore(jumpToAppStore);
          setImages({
            topImage: topPic,
            banners: [imageUrl],
          });
          if (prompt) {
            setDescs([prompt]);
          }
          if (btnContent) {
            setBtnDesc(btnContent);
          }
        } else {
          setFormerKey(moduleKey);
        }
      } else {
        setFormerKey(moduleKey);
      }
    } catch (e) {
      setFormerKey(moduleKey);
    }
  };

  const handleError = () => {
    currentLimit.current = false;
    querySettingImages();
  };

  const initLimitLink = async () => {
    try {
      const res: any = await apis.queryDownloadImageSetting(
        { moduleKey: "downloadDefault" },
        {
          "Content-Type": "application/json	",
        },
        {
          timeout: 1000,
        }
      );
      if (
        res.success &&
        res.code === 200 &&
        res.data?.positionList &&
        res.data?.positionList.length
      ) {
        const { positionList = [] } = res.data || {};
        const { imageUrl = banners[0], extraInfo = "" } = positionList[0];
        if (extraInfo) {
          const { urlLimitLinks = [] } = JSON.parse(extraInfo);
          if (
            urlLimitLinks?.length &&
            urlLimitLinks.includes(window.location.href)
          ) {
            const {
              topPic = imageConfig.topImage,
              prompt = "",
              btnContent = "",
              jumpToAppStore = false,
            } = JSON.parse(extraInfo);
            // 代表当前url被限制
            currentLimit.current = true;
            setImages({
              topImage: topPic,
              banners: [imageUrl],
            });
            if (prompt) {
              setDescs([prompt]);
            }
            if (btnContent) {
              setBtnDesc(btnContent);
            }
            setIosJumpToAppStore(jumpToAppStore);
          } else {
            handleError();
          }
        } else {
          handleError();
        }
      } else {
        handleError();
      }
    } catch (e) {
      handleError();
    }
  };

  const initDownloadPage = async () => {
    const queryObj = queryString.parse(window.location.search);
    await initLimitLink();
    if (
      !source ||
      source === "cdn" ||
      source === "app" ||
      source === "download"
    ) {
      if (queryObj.deeplink) {
        const _deeplink = window?.sensors?.use("Deeplink", { timeout: 290000 });
        window?.sensors?.quick("autoTrack");
        if (_deeplink) {
          setTimeout(() => {
            window.document
              .getElementById("btn-download")
              ?.addEventListener("click", function (e) {
                e.preventDefault();
                e?.stopPropagation();
                _deeplink.openDeepLink();
              });
          }, 500);
        }
      } else {
        if (deeplink) {
          window.location.href = createLink(deeplink);
          return;
        }
        const path = window.location.pathname;
        // ou麦卡和早餐卡和微信环境下不主动跳转
        if (currentLimit.current) {
          return;
        }
        if (
          path.endsWith("/app-bfcard.html") ||
          path.endsWith("app-ohcard.html") ||
          getWechat()
        ) {
          return;
        }
        setTimeout(() => {
          tryOpenOrDownload(deeplink, source);
        }, 2000);
      }
    }
  };

  useEffect(() => {
    initDownloadPage();
  }, []);

  function createLink(deeplink: string) {
    let href = deeplink || "mcdapp://";
    const args: any = [];
    const queryObj = queryString.parse(window.location.search);
    const pushArgs = (paramName: any) => {
      if (queryObj[paramName] !== undefined && queryObj[paramName] !== "")
        args.push([`${paramName}=${queryObj[paramName]}`]);
    };
    ["utm_term", "utm_source", "utm_campaign", "utm_medium", "utm_content"].map(
      (item) => pushArgs(item)
    );
    if (args.length > 0) {
      const querystr = args.join("&");
      if (href.match(/\?/)) {
        href += "&" + querystr;
      } else {
        href += "?" + querystr;
      }
    }
    return href;
  }

  function genDownloadLink(autoOpen = false) {
    let href = "https://a.app.qq.com/o/simple.jsp?pkgname=com.mcdonalds.gma.cn";
    if (userAgent.includes("iphone") || userAgent.includes("ios")) {
      href = "https://apps.apple.com/cn/app/id1054598922";
      if (autoOpen) href = createLink(deeplink);
    } else if (userAgent.includes("wechat")) {
      userAgent = navigator.userAgent.toLowerCase();
      if (userAgent.includes("android")) {
        return "";
      }
    } else if (userAgent.includes("android")) {
      href = "market://details?id=com.mcdonalds.gma.cn";
      if (autoOpen) href = createLink(deeplink);
    } else {
      href = "https://a.app.qq.com/o/simple.jsp?pkgname=com.mcdonalds.gma.cn";
    }
    return href;
  }

  function openDownloadPage(link: string) {
    sensors?.track("buttonClick", {
      belong_page: "活动页",
      button_name: "下载App",
    });
    window.location.href = link;
  }

  const getModuleKey = () => {
    const queryObj = queryString.parse(window.location.search);
    let moduleKey;
    if (queryObj.moduleKey) return queryObj.moduleKey;
    const path = window.location.pathname;
    switch (true) {
      case path === "/app":
        moduleKey = "downloadTransfer";
        break;
      case path === "/download":
        moduleKey = "downloadTransfer";
        break;
      case path.endsWith("/app-bfcard.html"):
        moduleKey = "downloadBreakfast";
        break;
      case path.endsWith("app-download.html"):
        moduleKey = "downloadThrow";
        break;
      case path.endsWith("app-ohcard.html"):
        moduleKey = "downloadOhmycard";
        break;
    }
    return moduleKey;
  };

  const onDownloadClick = lodash.throttle(
    tryOpenOrDownload.bind(null, deeplink, source, true),
    500,
    { leading: true, trailing: false }
  );

  return (
    <div className="container">
      <Modal
        open={popupOpen}
        closeOnEsc={true}
        onClose={() => {
          setPopupOpen(false);
        }}
        center
        classNames={{ modal: "modal-css", overlay: "overlay-css" }}
      >
        <div className="alert-container">
          <div>点击右上角在浏览器中打开</div>
          <div
            className="ok-button"
            onClick={() => {
              setPopupOpen(false);
            }}
          >
            确定
          </div>
        </div>
      </Modal>
      <Modal
        open={tipOpen}
        closeOnEsc={true}
        onClose={() => {
          setTipOpen(false);
        }}
        center
        classNames={{ modal: "modal-css", overlay: "overlay-css" }}
        styles={{
          modal: {
            width: "50%",
            margin: "auto",
          },
        }}
      >
        <div className="alert-container">
          {descs.map((desc: string) => (
            <div
              style={{
                fontSize: "16px",
                color: "#666",
                textAlign: "center",
              }}
              key={desc}
            >
              {desc}
            </div>
          ))}
          <div
            className="ok-button"
            id="ok-down"
            style={{
              minWidth: "70%",
              fontSize: "18px",
            }}
            onClick={() => {
              sensors?.track("buttonClick", {
                belong_page: window.location.href || "",
                button_name: "下载APP",
                module_name: "下载弹窗",
              });
              downloadAtOnce(source);
            }}
          >
            {btnDesc || "下载麦当劳APP"}
          </div>
        </div>
      </Modal>
      {source !== "cdn" && source !== "uc" && (
        <div className="header">
          <img
            src={imageConfig.topImage}
            alt=""
            onError={(e: any) => {
              e.target.onerror = null;
              e.target.src = "https://img.mcd.cn/gallery/6cf94bd1b9f6a9ad.png";
            }}
          />
          <span
            className="jump-span jump-left"
            onClick={() => {
              window.location.href =
                "https://www.mcdonalds.com.cn/page/Privacy-Policy";
            }}
          />
          <span
            className="jump-span jump-right"
            onClick={() => {
              window.location.href =
                "https://cdn.mcd.cn/cms/pages/CMAIntroduction.html";
            }}
          />
        </div>
      )}
      {imageConfig.banners.length ? (
        <AutoPlaySwipeableViews>
          {imageConfig.banners.map((item) => (
            <img
              key={item}
              alt=""
              onError={(e: any) => {
                e.target.onerror = null;
                e.target.src =
                  "https://img.mcd.cn/gallery/115cf520859b12da.png";
              }}
              className={"banner-img"}
              src={item}
            />
          ))}
        </AutoPlaySwipeableViews>
      ) : null}
      {(!source || source === "app") && !currentLimit.current && (
        <div
          className="footer footer-app"
          onClick={() => {
            sensors?.track("buttonClick", {
              belong_page: window.location.href || "",
              button_name: "下载APP",
              module_name: "下载按钮",
            });
            onDownloadClick();
          }}
        >
          <div id="btn-download" className={"btn-download"}>
            {btnDesc || "立即下载"}
          </div>
        </div>
      )}
      {(source === "cdn" || source === "uc") && !currentLimit.current && (
        <div className="footer footer-cdn">
          <div className={"bottom-bar"}>
            <a
              href="https://www.mcdonalds.com.cn/page/Privacy-Policy"
              className="link-privacy"
            >
              <img src={barImg} className="img-bar" alt="bar" />
            </a>
          </div>
        </div>
      )}
    </div>
  );
};

export default Download;
